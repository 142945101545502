import React from "react";
import GlobalStyles from 'styles/GlobalStyles';

import EventLandingPage from "demos/EventLandingPage.js";


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {


  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<EventLandingPage />} />
        </Routes>
      </Router>
    </>
  );
}



import React from "react";
import styled,{css} from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
// import zeropadImage from '/Users/jishnu/Downloads/treact 2/src/images/otive-zero-pad-periods.jpeg'
import zeropadImage from '../../images/otive-zero-pad-periods.jpeg';
import menstrualCupImage from '../../images/otive-menstrual-cup.jpeg';
import cupmedium from '../../images/Otive_Artwork_print- gold.jpg';
// import cupWithBox from '../../images/otive-cupwithbox.jpg'
import cup from '../../images/otive-cup.jpg'
import cupWithBox from '../../images/otive-cupwithbox.png'

import { Slide } from "react-slideshow-image";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-10 md:py-16 lg:py-20`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex flex-col md:flex-row justify-center items-center`,
  props.reversed ? tw`md:flex-row-reverse` : "md:flex-row",
  css`
    width: 100%;
    height: 100%;
  `,
]);

// const Image = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
// ]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
  css`
    width: 100%;
    height: 100%;
  `,
  tw`h-80 md:h-96 lg:h-144`,
  css`
    @media (max-width: 768px) {
      height: 420px;
      margin-left:0px
    }
  `,
]);


const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`md:text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:cupWithBox,
      subtitle: "",
      title: "What is Menstrual Cup?",
      description:
        "Introducing Otive zero pad periods, our revolutionary sustainable period product designed to redefine your menstrual experience. Crafted with your comfort and the environment in mind, Otive offers a new level of convenience and peace of mind during your period days.",
      url: "https://timerse.com"
    },

    {
      imageSrc:cup,
      subtitle: "",
      title: "What makes the Otive cup unique?",
      description:
        "The Otive menstrual cup is a reusable feminine hygiene product made of FDA-approved medical-grade silicone. Designed for comfortable and eco-friendly menstrual care, with a flexible and durable design, the cup creates a secure seal, providing leak protection for up to 12 hours.",
      url: "https://timerse.com"
    },

    {
      imageSrc:menstrualCupImage,
      subtitle: "",
      title: "How to use?",
      description:
        "The Otive menstrual cup offers ease of use, allowing effortless insertion, removal, and cleaning. This makes it a convenient and sustainable substitute for traditional menstrual products.",
      url: "https://timerse.com"
    }
  ];

  const slideImages = [
    {
      url: zeropadImage,
      caption: 'Slide 1'
    },
    {
      url: menstrualCupImage,
      caption: 'Slide 2'
    },
    {
      url: cupmedium,
      caption: 'Slide 3'
    },
  ];
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '252px' // Use '100%' instead of a fixed pixel value
  };
  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle style={{color:'#1A202C'}}>Products</HeadingTitle>
          <HeadingDescription>
            Here are some of the most popular Products.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              {/* <div style={{height:'500px',backgroundColor:'red'}}> */}
              <Image imageSrc={card.imageSrc} />
              {/* </div> */}
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};

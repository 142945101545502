import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css"; // Import the styles for the slideshow
import zeropadImage from '../../images/otive-zero-pad-periods.jpeg';
import menstrualCupImage from '../../images/otive-menstrual-cup.jpeg';
import cupmedium from '../../images/Otive_Artwork_print- gold.jpg';
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import slider1 from '../../images/otive-slider1.png';
import slider2 from '../../images/otive-slider2.png';
import slider3 from '../../images/otive-slider3.png';

const StyledHeader = styled(Header)`
  ${tw` max-w-none w-full `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen `};
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto  flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const spanStyle = {

  background: '#efefef',
  color: '#000000'
};

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '500px' // Use '100%' instead of a fixed pixel value
};

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
  const navLinks = [
    // <NavLinks key={1}>
    //   <NavLink href="#">About</NavLink>
    //   <NavLink href="#">Blog</NavLink>
    //   <NavLink href="#">Locations</NavLink>
    //   {/* <NavLink href="#">Pricing</NavLink> */}
    // </NavLinks>,
    // <NavLinks key={2}>
    //   <PrimaryLink href="/#">Hire Us</PrimaryLink>
    // </NavLinks>
  ];

  const slideImages = [
    {
      url: slider3,
      caption: 'Slide 1'
    },
    {
      url: slider2,
      caption: 'Slide 2'
    },
    {
      url: slider1,
      caption: 'Slide 3'
    },
  ];

  return (
    
    <Container style={{height:'auto'}}>
          <StyledHeader links={navLinks} />

      <HeroContainer style={{padding:'0'}}>

      <div style={{backgroundColor:'#b81f7b', paddingBlock:'5px', textAlign:'center'}}>
        <p style={{fontSize:'13px', color:'white'}}>How a Menstrual Cup Can Change Your Life</p>
      </div>
    
        <Slide>
          {slideImages.map((slideImage, index) => (
                      <img key={index} src={slideImage.url} alt="sss" style={{width:'100%'}}/>

         
          ))}
        </Slide>
      </HeroContainer>
    </Container>
  );
};
